import { useContext } from 'react';
import './index.scss';
import { DataContext } from '../Contexts/Data';
import { useNavigate } from 'react-router-dom';

function Update() {
    const dataLayer = useContext(DataContext);
    const navigate = useNavigate();

    const build_version = process.env.REACT_APP_VERSION || '0.0.0';

    const installUpdate = () => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.ready.then(registration => {
                registration.update().then(() => {
                    dataLayer.apiRequest.authentication.clearUpdateAvailable();
                    window.location.href = '/';
                });
            });
        }
    };

    const new_version = dataLayer.apiRequest.authentication.updateVersion();

    if (!new_version) {
        window.location.href = '/';
        return null;
    }

    return (
        <div className="Update">
            <h1>Update Required</h1>
            <p>An update is available and requires your attention.</p>
            <p><strong>New Version:</strong> {build_version}</p>
            <button onClick={installUpdate}>Install Update</button>
        </div>
    );
}

export default Update;
